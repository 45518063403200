.password_page {
	padding: 130px 0;
	text-align: center;
}

.password_page h1 {
	font-family: Messina-Bold;
	font-size: 18px;
	font-weight: 800;
	padding: 25px 0;
	text-align: center;
	color: #000000;
}

.password_page p {
	font-family: Messina-Regular;
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	text-align: center;

	color: #000000;
}

.password_page_form label {
	position: relative;
	left: -124px;
	color: #da0063;
	font-family: Messina;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
}

.password_page_form input {
	margin-top: 2px;
	width: 291px;
	height: 42px;
	border: 1px solid #a8a6a1;
	border-radius: 5px;
	outline: none;
	letter-spacing: 0.2em;
	padding-left: 15px;
}

.login_btn {
	margin-top: 24px;
	background: #da0063;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	width: 100px;
	height: 42px;

	font-family: Messina;
	font-size: 16px;
	line-height: 19px;

	color: #ffffff;
	cursor: pointer;
}
