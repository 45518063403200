body {
    overflow-x: hidden;
}

.map_img_container {
    position: relative;
    /* background-color: grey; */
    width: 100vw;
    height: calc(100vh - 80px);
    z-index: 1;
}

.map_img_container .map_background {
    width: 100%;
    height: calc(100vh - 80px);
}

.map_content {
    position: absolute;
    left: 30px;
    top: 30px;
}

.search_card {
    background-color: #ffffff;
    border: 1px solid #a8a6a1;
    box-sizing: border-box;
    border-radius: 5px;
    /*width: 819px;*/
    /*height: 54px;*/
    display: flex;
}

.search_input {
    width: 217px;
    height: 100%;
    border-right: 1px dashed red;
    padding: 0;
}

.search label {
    font-family: Buenos;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    color: #da0063;
}

.search_input label {
    font-family: Buenos;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    color: #da0063;
    margin-left: 17px;
}

.search_input input {
    outline: none;
    border: none;
    height: 18px;
    margin-left: 13px;
    font-family: Messina;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 190px;
    color: #000000;
}

.institution_dropdown {
    width: 170px;
    height: 100%;
    border-right: 1px dashed red;
}

.search_card label {
    font-family: Buenos;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    color: #da0063;
    margin-left: 17px;
}

.search_card input {
    outline: none;
    border: none;
    height: 18px;
    margin-left: 13px;
    font-family: Messina;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 140px;
    color: #000000;
}

.institution_dropdown_card {
    position: absolute;
    width: 261px;
    height: 171px;
    background: #ffffff;
    border: 1px solid #a8a6a1;
    border-radius: 5px;
    left: 217px;
    margin-top: 10px;
    overflow-y: scroll;
}

.institution_dropdown_card ul {
    padding: 0;
    list-style: none;
    font-family: Messina;
    font-size: 16px;
    color: #000000;
}

.institution_dropdown_card ul li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.institution_dropdown_card ul li:hover {
    background-color: #da0063;
    color: #ffffff;
}

.institution_dropdown_card ul li input {
    outline: none;
    border: none;
    background-color: transparent;
    padding-right: 70px;
}

.institution_dropdown_card ul li:hover input {
    color: #fff;
}

.department_dropdown {
    width: 172px;
    height: 100%;
    border-right: 1px dashed red;
}

.training_dropdown {
    width: 207px;
    height: 100%;
    border-right: 1px dashed red;
}

.search_btn {
    color: #fff;
    background-color: #da0063;
    width: 54px;
    height: 38px;
    margin-top: 22px;
    /*height: 54px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    margin-right: -10px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 35px;

    border-radius: 5px;
}

/* Departments DropDown */
.departments_dropdown_card {
    position: absolute;
    width: 271px;
    height: 171px;
    background: #ffffff;
    border: 1px solid #a8a6a1;
    border-radius: 5px;
    left: 387px;
    margin-top: 10px;
    overflow-y: scroll;
}

.departments_dropdown_card ul {
    padding: 0;
    list-style: none;
    font-family: Messina;
    font-size: 16px;
    color: #000000;
}

.departments_dropdown_card ul li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.departments_dropdown_card ul li:hover {
    background-color: #da0063;
    color: #ffffff;
}

.departments_dropdown_card ul li input {
    outline: none;
    border: none;
    background-color: transparent;
    padding-right: 70px;
}

.departments_dropdown_card ul li:hover input {
    color: #fff;
}

/* Training Dropdown */
.training_dropdown_card {
    position: absolute;
    width: 271px;
    height: 171px;
    background: #ffffff;
    border: 1px solid #a8a6a1;
    border-radius: 5px;
    left: 557px;
    margin-top: 10px;
    overflow-y: scroll;
}

.training_dropdown_card ul {
    padding: 0;
    list-style: none;
    font-family: Messina;
    font-size: 16px;
    color: #000000;
}

.training_dropdown_card ul li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.training_dropdown_card ul li:hover {
    background-color: #da0063;
    color: #ffffff;
}

.training_dropdown_card ul li input {
    outline: none;
    border: none;
    background-color: transparent;
    padding-right: 70px;
}

.training_dropdown_card ul li:hover input {
    color: #fff;
}

/* Search Result */
.search_reasult_card {
    /*position: absolute;*/
    background-color: #ffffff;
    /*width: 520px;*/
    margin-top: 6px;
    max-height: calc(100vh - 180px);
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.search_reasult_card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search_reasult_card ul li {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 10px;
}

.search_reasult_card ul li .img {
    background-image: url('../assets/externallinkicon.svg');
    width: 16px;
    height: 16px;
    margin-top: 16px;
}

.search_reasult_card ul li:hover {
    background-color: #da0063;
    color: #fff;
}

.search_heading {
    cursor: pointer;
}

.search_reasult_card ul li:hover .search_heading .img {
    background-image: url('../assets/external-link-icon-white.svg');
    width: 16px;
    height: 16px;
    margin-top: 16px;
}

.search_reasult_card .search_tags span {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 10px;
    font-family: Buenos;
    width: auto;
    padding: 8px 10px;
    text-align: center;
    margin-right: 13px;
}

.search_reasult_card .search_heading {
    display: flex;
    justify-content: space-between;
}

.search_reasult_card .search_heading h3 {
    font-family: Buenos;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
}

.search_reasult_card .search_content p {
    font-family: Messina;
    font-size: 13px;
}

/* Map Red Circles */
.map_location_circles .circle_1 {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #da0063;
    border-radius: 50%;
    top: 386px;
    left: 804px;
}

.map_location_circles .circle_1:hover .hover_card {
    opacity: 1;
}

.hover_card {
    width: 235px;
    background-color: white;
    margin-top: 30px;
    opacity: 0;
    padding: 10px;
    border-radius: 5px;
}

.hover_card .hover_card_tags span {
    color: #ffffff;
    font-family: Buenos;
    font-weight: bold;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
}

.hover_card h3 {
    font-family: Buenos;
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
}

/* Location Details Card */
.location_details_card {
    max-height: calc(100vh - 180px);
    border-radius: 5px;
    background-color: #fff;
    top: 30px;
    right: 30px;
    padding: 10px 15px;
    overflow-y: scroll;
}

.location_details_card h3 a {
    margin-left: 10px
}

.location_details_card .metadata {
    font-size: 14px;
}

.location_details_card .metadata label {
    font-weight: bold;
}

.location_details_card .loaction_card_tags span {
    background-color: #a8a6a1;
    border-radius: 5px;
    padding: 5px;
    margin-right: 15px;
    color: #fff;
    font-family: Messina;
    font-weight: bold;
    font-size: 8px;
    line-height: 7px;
}

.location_details_card {
    font-size: 14px
}

.location_details_card h3 {
    font-family: Messina;
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
}

.location_details_card p {
    font-family: Messina;
    font-weight: normal;
    font-size: 14px;
}

.location_details_card a {
    text-decoration-line: underline;
    font-family: Messina;
    font-weight: normal;
    /*font-size: 11px;*/
    line-height: 23px;
    color: #000000;
}

/* Scroll Bar */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
}

.modal-90w {
    width: 80%
}