body {
	margin: 0;
	padding: 0;
	box-sizing: source;
}

@font-face {
	font-family: Buenos;
	src: url(./fonts/BuenosAiresWeb-Bold.woff);
}

@font-face {
	font-family: Messina;
	src: url(./fonts/MessinaModernWeb-Light.woff);
}

@font-face {
	font-family: Messina-Bold;
	src: url(./fonts/MessinaModernTrialWeb-Bold.woff);
}

@font-face {
	font-family: Messina-Regular;
	src: url(./fonts/MessinaModernTrialWeb-Regular.woff);
}
